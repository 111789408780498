import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";

// Pages and Componants
import Home from "./pages/Home";
import PageNotFound from "./pages/404";

//Layouts
import RootLayout from "./layouts/RootLayout";
import MasterMentor from "./layouts/MasterMentor";

//HFW Routes
import HfwGradeThree from "./pages/hfWord/DfwGradeThree";
import HfWordHome from "./pages/hfWord/HfWordHome";

// One Page Route
import HfwGradeOneComponents from "./componants/HfwGradeOneComp";
import HfwGradeTwoComp from "./componants/HfwGradeTwoComp";
import HfwGradeThreeComp from "./componants/HfwGradeThreeComp";

//Admin Route
import AdminDashboard from "./pages/admin/AdminDashboard";
import Register from "./pages/Register";
import Login from "./pages/Login";

//User Route
import UserDashboard from "./layouts/UserDashboard";

//HFW Route
import HfwTabs from "./componants/HfwTabs";
import DfwGradeThreeComp from "./componants/DfwGradeThreeComp";
import Hfword from "./pages/admin/Hfword";
import Dfword from "./pages/admin/Dfword";

//Math Route
import MentoringData from "./pages/admin/MentoringData";
import LessonPlandBuilder from "./pages/lessonPlan/LessonPlanBuilder";
import MasterManualActicle1 from "./pages/masterMentorPage/MasterManualActicle1";
import MasterManualActicle2 from "./pages/masterMentorPage/MasterManualActicle2";
import MasterManualActicle3 from "./pages/masterMentorPage/MasterManualActicle3";
import MasterManualActicle4 from "./pages/masterMentorPage/MasterManualActicle4";
import MasterManualActicle5 from "./pages/masterMentorPage/MasterManualActicle5";
import MasterManualActicle6 from "./pages/masterMentorPage/MasterManualActicle6";
import MasterManualActicle7 from "./pages/masterMentorPage/MasterManualActicle7";

import MasterPage from "./pages/masterMentorPage/MainPage";
import AdminLayout from "./layout/admin/AdminLayout";
import AdminHome from "./layout/admin/AdminHome";
import AdminResource from "./layout/admin/AdminResource";
import AdminProfile from "./layout/admin/AdminProfile";

//create Route Element
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<RootLayout />}>
        <Route index element={<Home />} />

        {/* Auth Route */}
        <Route path="/login" element={<Login />}>
          <Route index element={<Login />} />
          <Route path="register" element={<Register />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Master Mentor */}
      {/* <Route path="/master" element={<MasterMentor />}>
        <Route index element={<MasterPage />} />
        <Route path="/master/article1" element={<MasterManualActicle1 />} />
        <Route path="/master/article2" element={<MasterManualActicle2 />} />
        <Route path="/master/article3" element={<MasterManualActicle3 />} />
        <Route path="/master/article4" element={<MasterManualActicle4 />} />
        <Route path="/master/article5" element={<MasterManualActicle5 />} />
        <Route path="/master/article6" element={<MasterManualActicle6 />} />
        <Route path="/master/article7" element={<MasterManualActicle7 />} />
      </Route> */}

      {/* Resource Routes  */}
      <Route path="dashboard" element={<UserDashboard />}>
        <Route index element={<HfWordHome />} />
        <Route path="hfw" element={<HfwTabs />} />
        <Route path="dfw" element={<HfwGradeThree />} />
        <Route path="bb" />
        <Route path="ls" />
        <Route path="lessonbuilder" element={<LessonPlandBuilder />} />
      </Route>

      {/* Adimin Layouts */}
      <Route path="admin2" element={<AdminDashboard />}>
        <Route path="hfword" element={<Hfword />} />
        <Route path="dfword" element={<Dfword />} />
        <Route path="mentoring" element={<MentoringData />} />
      </Route>

      <Route path="admin" element={<AdminLayout />}>
        <Route index element={<AdminHome />} />
        <Route path="resource" element={<AdminResource />} />
        <Route path="profile" element={<AdminProfile />} />
      </Route>

      {/* Public Routes */}
      <Route
        path="dashboard/hfw/grade1/:id"
        element={<HfwGradeOneComponents />}
      />
      <Route path="dashboard/hfw/grade2/:id" element={<HfwGradeTwoComp />} />
      <Route path="dashboard/hfw/grade3/:id" element={<HfwGradeThreeComp />} />
      <Route
        path="dashboard/dfw/:weekId/:dayId"
        element={<DfwGradeThreeComp />}
      />
    </>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
