import React from "react";

function AdminProfile() {
  return (
    <div>
      <h1>Amin Profile</h1>
    </div>
  );
}

export default AdminProfile;
